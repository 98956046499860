<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="6" lg="5" xl="4" class="text-center">
      <v-img
        src="@assets/illustration/man_on_phone.svg"
        contain
        max-height="30vh"
      />

      <h2 class="mt-5">Hai problemi con il tuo dispositivo?</h2>
      <p class="mt-3 mb-10">
        Non temere, il tuo tecnico può assisterti per ogni tua necessità:
        scarica il software per il tuo sistema operativo per consentirgli di
        accedere in remoto.
      </p>

      <PrepareRemoteClient v-if="isWindows || isMacOs" />

      <v-btn
        v-else-if="isAndroid"
        color="secondary"
        x-large
        href="https://play.google.com/store/apps/details?id=it.ennova.skillo.qs"
      >
        <v-icon left large class="mr-5">mdi-android</v-icon>
        Scarica per Android
      </v-btn>

      <v-btn v-else color="secondary" x-large disabled>
        <v-icon left large class="mr-5">mdi-emoticon-sad</v-icon>
        Non disponibile
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  isMobile,
  isAndroid,
  isWindows,
  isMacOs,
  isIOS,
} from 'mobile-device-detect'
import PrepareRemoteClient from '@components/public/PrepareRemoteClient.vue'
export default {
  name: 'Home',
  components: { PrepareRemoteClient },
  data: () => ({
    isMobile,
    isAndroid,
    isIOS,
    isWindows,
    isMacOs,
  }),
}
</script>

<style scoped></style>
