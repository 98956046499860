<template>
  <v-row align="center" justify="center">
    <v-col cols="12" :sm="8" :md="8" :lg="4" class="text-center">
      <v-img src="@assets/illustration/man_on_phone.svg" contain />

      <h2 class="mt-5">
        Grazie per aver usato<br />
        il nostro servizio!
      </h2>
      <RateSession :project="$route.params.project" :sid="$route.params.sid" />
    </v-col>
  </v-row>
</template>

<script>
import RateSession from '@components/public/EQS/RateSession.vue'

export default {
  name: 'SessionComplete',
  components: { RateSession },
}
</script>

<style scoped>
.gradientRatingBg {
  background: rgb(255, 0, 10);
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 10) 0%,
    rgba(255, 255, 0, 1) 25%,
    rgba(255, 255, 0, 1) 50%,
    rgba(127, 255, 0, 1) 75%,
    rgba(23, 201, 0, 1) 100%
  );
  border-radius: 10px;
}
</style>
