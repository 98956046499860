<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4" class="text-center">
      <v-img src="@assets/illustration/man_on_phone.svg" contain />

      <h2 class="mt-5">Hai problemi con il tuo dispositivo?</h2>
      <p class="mt-3 mb-10">
        Non temere, il tuo tecnico può assisterti per ogni tua necessità:
        scarica l'applicativo per il tuo sistema operativo per consentirgli di
        accedere in remoto.
      </p>

      <v-btn
        v-if="isWindows"
        color="secondary"
        x-large
        href="https://get.anydesk.com/0N9eNFco/D1S_Client.exe"
      >
        <v-icon left large class="mr-5">mdi-microsoft-windows</v-icon>
        Scarica per Windows
      </v-btn>

      <v-btn
        v-else-if="isMacOs"
        color="primary"
        x-large
        href="https://get.anydesk.com/e9zs8aPx/D1S_Client.dmg"
      >
        <v-icon left large class="mr-5">mdi-apple</v-icon>
        Scarica per Mac OSX
      </v-btn>

      <v-btn
        v-else-if="isAndroid"
        color="secondary"
        x-large
        href="/static/apps/onesolution-quicksupport-app-production-3.0.3.0.apk"
      >
        <v-icon left large class="mr-5">mdi-android</v-icon>
        Scarica per Android
      </v-btn>

      <v-btn v-else color="secondary" x-large disabled>
        <v-icon left large class="mr-5">mdi-emoticon-sad</v-icon>
        Non disponibile
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  isMobile,
  isAndroid,
  isWindows,
  isMacOs,
  isIOS,
} from 'mobile-device-detect'
export default {
  name: 'Home',
  data: () => ({
    isMobile,
    isAndroid,
    isIOS,
    isWindows,
    isMacOs,
  }),
}
</script>

<style scoped></style>
