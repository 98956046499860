import { render, staticRenderFns } from "./RateSession.vue?vue&type=template&id=1650e714&scoped=true&"
import script from "./RateSession.vue?vue&type=script&lang=js&"
export * from "./RateSession.vue?vue&type=script&lang=js&"
import style0 from "./RateSession.vue?vue&type=style&index=0&id=1650e714&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1650e714",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFadeTransition,VProgressCircular,VRow})
