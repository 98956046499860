<template>
  <div>
    <v-fade-transition>
      <div v-if="!session">
        <v-checkbox v-model="accept" dense class="mt-6" light>
          <template v-slot:label>
            <span class="small">
              Dichiaro di aver preso visione dei
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a target="_blank" href="/terms.html" @click.stop v-on="on"
                    >Termini e Condizioni</a
                  >
                </template>
                Apri in una nuova finestra
              </v-tooltip>
              del servizio
            </span>
          </template>
        </v-checkbox>

        <v-btn
          v-if="isWindows"
          color="secondary"
          x-large
          :disabled="!accept"
          :loading="loading"
          @click="createSession"
        >
          <v-icon left large class="mr-5">mdi-microsoft-windows</v-icon>
          Scarica per Windows
        </v-btn>

        <v-btn
          v-else-if="isMacOs"
          color="primary"
          x-large
          :disabled="!accept"
          :loading="loading"
          @click="createSession"
        >
          <v-icon left large class="mr-5">mdi-apple</v-icon>
          Scarica per Mac OSX
        </v-btn>
      </div>
      <div v-else>
        <h6 class="text-h6 text--secondary">Codice Sessione</h6>
        <h1 class="text-h3">{{ session.session_id }}</h1>

        <p class="subtitle-2 mt-6">
          Esegui il file che è appena stato scaricato e comunica questo codice
          al tecnico affinché possa connettersi al tuo dispositivo.
        </p>
        <p>
          <v-btn small text color="primary" @click="startDownload">
            Download non parte in automatico? clicca qui
          </v-btn>
        </p>
        <p>
          <v-btn small text color="primary" @click="session = null">
            Crea Nuova Sessione
          </v-btn>
        </p>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import { isWindows, isMacOs } from 'mobile-device-detect'
import { mapState } from 'vuex'
import { getBrowserFingeprint } from '@utils/browser.js'
import { netRequest } from '@api/client.js'
import { getRandomInt } from '@utils/project.js'

export default {
  name: 'PrepareRemoteClient',
  data: () => ({
    isWindows,
    isMacOs,
    accept: false,
    loading: false,
    session: null,
  }),
  computed: {
    ...mapState('utils', ['env']),
  },
  methods: {
    async createSession() {
      this.loading = true
      try {
        // eslint-disable-next-line camelcase
        const [device_fingerprint] = await Promise.all([
          getBrowserFingeprint(),
          // GetFirebaseMessaging().getToken(),
        ])

        this.session = await netRequest('POST', '../quicksupport/remote', {
          project: this.env.key,
          client_code: `${getRandomInt(1000, 9999)}`,
          device_push_id: device_fingerprint,
          device_fingerprint,
        })

        this.startDownload()
      } catch (err) {
        console.warn('createSession', err)
      } finally {
        this.loading = false
      }
    },
    startDownload() {
      return this.downloadFile(this.session.gs_agent_url)
    },

    downloadFile(filePath) {
      const link = document.createElement('a')
      link.href = filePath
      link.download = filePath.substr(filePath.lastIndexOf('/') + 1)
      link.click()
    },
  },
}
</script>

<style scoped></style>
