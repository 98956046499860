<template>
  <v-footer app class="text--secondary caption">
    <v-row dense no-gutters class="mt-2">
      <v-col>
        <p>
          <strong>QuickSupport</strong><br />
          Powered by Ennova SPA
        </p>
      </v-col>
      <v-col class="text-right">
        <a href="https://www.ennova.it">
          <img :src="require('@assets/ennova_logotype.svg')" height="36" />
        </a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'SessionCreditsFooter',
}
</script>
