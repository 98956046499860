<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="6" class="text-center">
      <v-img src="@assets/illustration/man_on_phone.svg" contain />

      <h2 class="mt-5">Sessione non valida</h2>
      <p class="mt-3 mb-10">
        Il link è riferito ad una sessione scaduta o non più valida.
      </p>
    </v-col>
  </v-row>
</template>

<script>
import {
  isMobile,
  isAndroid,
  isWindows,
  isMacOs,
  isIOS,
} from 'mobile-device-detect'
export default {
  name: 'SessionExpired',
  data: () => ({
    isMobile,
    isAndroid,
    isIOS,
    isWindows,
    isMacOs,
  }),
}
</script>

<style scoped></style>
