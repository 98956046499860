<template>
  <div class="text-center">
    <p v-if="project && sid && !done" class="mt-3 mb-10">
      Il tuo giudizio per noi è importante, esprimi per favore una valutazione
      dell'esperienza avuta con il servizio di Video Assistenza.
    </p>
    <v-fade-transition>
      <v-row
        v-if="project && sid && !done"
        justify="space-around"
        dense
        class="gradientRatingBg"
      >
        <v-btn v-for="r in rates" :key="r" small icon @click="saveRate(r)">
          {{ r }}
        </v-btn>
      </v-row>
    </v-fade-transition>

    <v-fade-transition>
      <v-progress-circular
        v-if="loading"
        :width="3"
        :size="50"
        color="green"
        indeterminate
        class="my-5"
      />
    </v-fade-transition>
  </div>
</template>

<script>
import { netRequest } from '@api/client.js'

export default {
  name: 'RateSession',
  // props: ['project', 'sid'],
  props: {
    project: {
      type: String,
      default: null,
    },
    sid: {
      type: String,
      default: null,
    },
    rates: {
      type: Array,
      required: false,
      default() {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      },
    },
  },
  data: () => ({
    loading: false,
    done: false,
  }),
  methods: {
    async saveRate(rating) {
      try {
        this.loading = true

        await netRequest(
          'POST',
          `../quicksupport/rate/${this.project}/${this.sid}`,
          {
            rating,
          }
        )

        this.loading = false

        this.done = true
        await this.$dialog.notify.success('Grazie per il tuo feedback!', {
          position: 'bottom-left',
          timeout: 5000,
        })
      } catch (err) {
        console.info('Unable to save rating', err)
        this.loading = false
        await this.$dialog.notify.error('Si è verificato un errore :(', {
          position: 'bottom-left',
          timeout: 5000,
        })
      }
    },
  },
}
</script>

<style scoped>
.gradientRatingBg {
  background: rgb(255, 0, 10);
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 10) 0%,
    rgba(255, 255, 0, 1) 25%,
    rgba(255, 255, 0, 1) 50%,
    rgba(127, 255, 0, 1) 75%,
    rgba(23, 201, 0, 1) 100%
  );
  border-radius: 10px;
}
</style>
