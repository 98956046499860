<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="6" class="text-center">
      <v-img src="@assets/illustration/man_on_phone.svg" contain />

      <h2 class="mt-5">Sessione Sospesa</h2>
      <p class="mt-3 mb-10">
        La sessione è stata sospesa.<br />
        Attendi il tuo agente prima di riconnetterti.
      </p>

      <v-btn
        v-if="$route.params.sid"
        color="primary"
        large
        block
        @click="resume"
      >
        <v-icon left>mdi-restart</v-icon>
        Riprendi
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  isMobile,
  isAndroid,
  isWindows,
  isMacOs,
  isIOS,
} from 'mobile-device-detect'
export default {
  name: 'SessionSuspended',
  data: () => ({
    isMobile,
    isAndroid,
    isIOS,
    isWindows,
    isMacOs,
  }),
  methods: {
    resume() {
      return this.$router.replace({
        name: 'public_video',
        params: {
          project: this.$route.params.project,
          sid: this.$route.params.sid,
          resumed: true,
        },
      })
    },
  },
}
</script>

<style scoped></style>
