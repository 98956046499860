import store from '@state/store.js'

export function getRandomInt(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getCurrentProject = () => {
  return store.state?.sessions?.env?.key
}

export function projectCustomComponent(comp) {
  const AsyncHandler = () => ({
    component: import('@components/public/' + getCurrentProject() + '/' + comp),
    error: require('@components/public/EQS/' + comp).default,
    timeout: 1000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
