<template>
  <v-app-bar app color="primary" dark fixed dense>
    <v-toolbar-title>
      <span class="text-uppercase font-weight-light">Quick</span
      ><span class="text-uppercase font-weight-bold">SUPPORT</span>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PublicTitleBar',
}
</script>
